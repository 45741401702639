module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-accessibilityjs/gatsby-browser.js'),
      options: {"plugins":[],"injectStyles":"\n        .accessibility-error {\n          box-shadow: 0 0 3px 1px #f00;\n          background-color: rgba(255, 0, 0, 0.25);\n          position: relative;\n        }\n        .accessibility-error:before {\n          content: \"A11Y\";\n          position: absolute;\n          top: 0;\n          left: 0;\n          color: #fff;\n          font-size: 10px;\n          background-color: rgba(255, 0, 0, 0.5);\n          transform: translateY(-100%);\n        }\n      "},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sport Club Internacional","short_name":"Sport Club Internacional","description":"Site oficial do Sport Club Internacional","start_url":"/","background_color":"#F5F5F5","theme_color":"#B61918","display":"minimal-ui","icon":"/home/circleci/sci/src/images/escudo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fcbc593af2014b25e88ad70002f8473d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-153357442-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"analytics":true},"credentials":{"apiKey":"AIzaSyATYUNCtiGbaab9uU_skVC7eRxF_XtRjps","authDomain":"sites-dfd10.firebaseapp.com","databaseURL":"https://sites-dfd10.firebaseio.com","projectId":"sites-dfd10","storageBucket":"sites-dfd10.appspot.com","messagingSenderId":"464375016269","appId":"1:464375016269:web:ff650aec909af9b451e504","measurementId":"G-34XHNC8GGW"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"/home/circleci/sci/src/theme/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/circleci/sci/src/layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://internacional.com.br"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-153357442-1","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-624869182"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
